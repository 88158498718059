/////
// Pack com tudo do tema Inspinia, base de frontend para desse projeto
///////

// These polyfills ensure that modern JS features (promises, async/await, arrow function
// expressions, and more) are available on the browsers you intend to support
import 'core-js/stable'
import 'regenerator-runtime/runtime'


// Bootstrap 3.4.1
import 'bootstrap-sass/assets/javascripts/bootstrap'

// Bootstrap 4.5
// import 'bootstrap/dist/js/bootstrap'

// JQuery UI
require('jquery-ui')

///
// External plugins used to this pack
////

// Toastr notifications
import toastr from 'toastr'
window.toastr = toastr

require('metismenu/dist/metisMenu')
require('jquery-slimscroll')

import Tipped from '@staaky/tipped/dist/js/tipped'
window.Tipped = Tipped


// Adiciona todos os arquivos JS criados pelo tema Inspinia
import '../inspinia'

// Adiciona todos os stylesheets
require("../inspinia/stylesheets")
